// extracted by mini-css-extract-plugin
export var trailergrid = "index-module--trailergrid--2wMxV";
export var kickstarter = "index-module--kickstarter--1fP58";
export var trailer = "index-module--trailer--NPvTL";
export var aboutsection = "index-module--aboutsection--1K1wi";
export var primaryscreenshot = "index-module--primaryscreenshot--2zQ1u";
export var secondaryscreenshots = "index-module--secondaryscreenshots--1M1Vt";
export var gamefeaturegrid = "index-module--gamefeaturegrid--3yMFJ";
export var gamefeature = "index-module--gamefeature--3SpQk";
export var contactgrid = "index-module--contactgrid--1b55Z";
export var contactform = "index-module--contactform--1dJmJ";
export var contacticon = "index-module--contacticon--3KPMn";
export var sendbtn = "index-module--sendbtn--tcj7P";