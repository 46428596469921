import React from "react"

import Layout from "../components/layout"
import Social from "../components/social"

import * as IndexStyles from "./index.module.scss"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPaperPlane,
  faUser,
  faEnvelope,
  faCommentDots,
} from '@fortawesome/free-solid-svg-icons'

import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import Head from "../components/head"

const Home = () => {
  const data = useStaticQuery(graphql`
  query{
      site{
          siteMetadata{
              description
              siteUrl
          }
      }
  }
`)

  const schema =
  {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "KingsCourtStudio",
    "description" : data.site.siteMetadata.description,
    "url": data.site.siteMetadata.siteUrl,
    "logo": "../media/images/TWWWLogo.png",
    "sameAs": "https://twitter.com/StudioCourt"
  }

  return(
    <Layout>
      <Head title="Home" description={data.site.siteMetadata.description} pathname="index" schemaMarkup={schema} />
        <section id="Trailer-Section">
          <div className={IndexStyles.trailergrid}>
            <div className={IndexStyles.trailer}>
              <iframe src="https://www.youtube.com/embed/IAcOztGrhco" title="The War Will Win (Kickstarter Demo Trailer)" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div>
              <h2>Every war has a price</h2>
              <p>Troubles brewing at the borders and something isn’t normal about it. All the factions are preparing for something! Does the disappearance of civilians at the border, related to this? Whatever is the reason, you'll soon learn that all war comes at a price, and no matter who remains to stand, only The War will ever win.</p>
            </div>
          </div>

          <div className={IndexStyles.kickstarter}><a href="https://www.kickstarter.com/projects/twww/the-war-will-win" target="_blank" rel="noreferrer" aria-label="Now on kickstarter"><StaticImage src="../media/images/Kickstarter.png" alt="Kickstarter"/></a></div>
        </section>

        <section id="About-Section" className={IndexStyles.aboutsection}>

          <div class="dashed-line"></div>

          <div className={IndexStyles.primaryscreenshot}>
          <Zoom overlayBgColorStart="rgba(0, 0, 0, 1)" overlayBgColorEnd="rgba(0, 0, 0, 1)"><StaticImage src="../media/images/screenshots/Screenshot-1.png" alt="Screenshot 1"/></Zoom>
          </div>
          <div className={IndexStyles.secondaryscreenshots}>
            <Zoom overlayBgColorStart="rgba(0, 0, 0, 1)" overlayBgColorEnd="rgba(0, 0, 0, 1)"><StaticImage src="../media/images/screenshots/Screenshot-2.png" alt="Screenshot 2"/></Zoom>
            <Zoom overlayBgColorStart="rgba(0, 0, 0, 1)" overlayBgColorEnd="rgba(0, 0, 0, 1)"><StaticImage src="../media/images/screenshots/Screenshot-3.png" alt="Screenshot 3"/></Zoom>
          </div>

          <div class="dashed-line"></div>

          <h2>The fortunes of war</h2>
            <div className={IndexStyles.gamefeaturegrid}>
              <div className={IndexStyles.gamefeature}>
                <h3>Turn-based strategy</h3>
                <Zoom overlayBgColorStart="rgba(0, 0, 0, 1)" overlayBgColorEnd="rgba(0, 0, 0, 1)"><StaticImage src="../media/images/gamefeatures/TurnBasedStrategy.png" alt="The War Will Win Logo"/></Zoom>
                <ul>
                    <li>Classic turn-based game</li>
                    <li>Carefully play your move and strategise ahead</li>
                    <li>Command each unit separately to use their unique skills in various ways</li>
                </ul>
              </div>
              <div className={IndexStyles.gamefeature}>
                <h3>Cards and Deck Building</h3>
                <Zoom overlayBgColorStart="rgba(0, 0, 0, 1)" overlayBgColorEnd="rgba(0, 0, 0, 1)"><StaticImage src="../media/images/gamefeatures/Card.png" alt="Cards"/></Zoom>
                <ul>
                    <li>A mix between precise strategy and randomness of cards</li>
                    <li>Strike a balance between building a deck with powerful but costly cards or weaker but cheap cards</li>
                    <li>You are in control but are also at the mercy of the cards</li>
                </ul>
              </div>
              <div className={IndexStyles.gamefeature}>
                <h3>Choose your own story</h3>
                <Zoom overlayBgColorStart="rgba(0, 0, 0, 1)" overlayBgColorEnd="rgba(0, 0, 0, 1)"><StaticImage src="../media/images/gamefeatures/ChooseYourStory.png" alt="Choose your own story"/></Zoom>
                <ul>
                    <li>Every decision you make affects the story and its characters</li>
                    <li>Your decision will affect the outcome of your faction and other kingdoms</li>
                </ul>
              </div>
            </div>
          </section>

          <div class="dashed-line"></div>
          
          <section id="Contact-Section">
            <div className={IndexStyles.contactgrid}>
              <div>
                <h2>Stay up to date!</h2>
                <p>You can follow us on social media to stay up to date with the game or to send us feedback through dms or using the form below. We post on our social media page, whenever somthing new happens!</p>
                <p><br></br>You can also download the games, core mechanics demo on our itch.io page</p>
                <Social/>
              </div>
              <div className={IndexStyles.contactform}>
                <h2>Feedback!</h2>
                <form name="Contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="Contact" />
                  <div>
                    <FontAwesomeIcon className={IndexStyles.contacticon} icon={faUser} size="2x" />
                    <input type="text" name="name" placeholder="Name" required/>
                  </div>
                  <div>
                    <FontAwesomeIcon className={IndexStyles.contacticon} icon={faEnvelope} size="2x" />
                    <input type="email" name="email" placeholder="Email" required/>
                  </div>
                  <div>
                    <FontAwesomeIcon className={IndexStyles.contacticon} icon={faCommentDots} size="2x" />
                    <textarea name="message" placeholder="your message here!" cols="30" rows="6"/>
                  </div>
                  <div className={IndexStyles.sendbtn}>
                    <input type="submit" value="Send"/>
                    <FontAwesomeIcon className={IndexStyles.contacticon} icon={faPaperPlane} size="2x" spacing={100}/>
                  </div>
                </form>
              </div>
            </div>
          </section>
    </Layout>
  )
}

export default Home